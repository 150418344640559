import React from 'react';
import "../../assets/scss/_menu.scss"
import breadcrumbsImg from '../../assets/images/breadcrumbs/1.jpg'

const Breadcrumb = (props) => {
    const { courseName, courseTitle, courseBannerImg, courseAuthor, courseAuthorImg, courseLesson, courseEnrolled } = props;
	
	return (
		<div className="react-breadcrumbs single-page-breadcrumbs">
			<div className="breadcrumbs-wrap">
			{/* 	<div className=" desktop-div ">
				<h1>{ courseName}</h1>
				</div> */}
				
				<img className="mobile" src={courseBannerImg} alt={courseTitle}/>
				<div className="breadcrumbs-inner">
					<div className="container">
						<div className="breadcrumbs-text">
						
						<div className="back-nav">
							
									
							
								
							</div>
						</div>
					</div>
				</div>
			</div>                
		</div>
	);
}

export default Breadcrumb;




