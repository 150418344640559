import axios from 'axios'
import { BACKEND_URL } from '../global';

export const all_courses_for_homepage = 'api/all_courses_for_homepage/'
export const single_course = 'api/single_course'
export const enroll_in_course = 'api/enroll_in_course/'
export const course_reviews = 'api/course_reviews'
export const all_courses_for_calendar = 'api/all_courses_for_calendar'
export const add_course_reviews= 'api/course_reviews'

export const getAPI = async (URL, Id = null) => {
    try {
        const AddId = Id ? `/${Id}` : ''
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${BACKEND_URL}/${URL}${AddId}`,
            headers: {}
        };
        const response = await axios.request(config)
        if (response.status === 200)
            return response.data?.data;
    }
    catch (err) {
        console.log(err)
    }
}


export const postAPI = async (URL, data) => {
    try {
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${BACKEND_URL}/${URL}`,
            headers: {
            },
            data: data
        };
        const response = await axios.request(config)
        if (response.status === 200)
            return response.data?.data;
    }
    catch (err) {
        console.log(err)
    }
}

