import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Faq from "../../components/Faq";
import axios from "axios";
import { BACKEND_URL } from "../../global";

const CourseDetailsMain = (props) => {
  const [reviews, setReviews] = useState([]);
  const [reviewSummary, setReviewSummary] = useState([]);
  const [hovered, setHovered] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    review: "",
    rating: 0,
  });
  const location = useLocation();
  const courseURL = location.pathname.split("/");
  const courseId = Number(courseURL[2]);
  let tab1 = "Discription",
    tab2 = "Reviews",
    tab3 = "FAQ";
  const tabStyle = "nav nav-tabs";

  const {
    
    Course,
    courseReviewSummary,
    courseReviews,
    courseFaq,
    courseImg,
    courseName,
    courseLesson,
    courseDuration,
    coursePrice,
  } = props;
console.log( Course," Course===========")
  const handleRatingChange = (rating) => {
    setFormData({
      ...formData,
      rating: rating,
    });
    console.log(rating);
  };
  const submitHandler = (event) => {
    const form = event.target;
    const name = form.name.value;
    const email = form.email.value;
    const rating = formData.rating;

    const review = form.review.value;

    const data = {
      name,
      email,
      course_id: courseId,
      rating,
      review,
    };

    axios
      .post("http://backend.pioneerfirstaid.com.au/api/course_reviews/", data)
      .then((response) => {
        setReviews((prevReviews) => [response.data, ...prevReviews]);
        console.log("New review added:", response.data);
      })
      .catch((error) => {
        console.log(
          "Error:",
          error.response ? error.response.data : error.message
        );
      });
  };

  return (
    <div className="back__course__page_grid react-courses__single-page pb---16 pt---110">
      <div className="container pb---70">
        <div className="row">
          <div className="col-lg-8">
            <div className="course-details-video mb---30">
              <img className="desktop" src={courseImg} alt="loading..." />
              {/* <iframe width="100%" height="400" src="https://www.youtube.com/embed/e5Hc2B50Z7c" title="YouTube video player" allow="accelerometer"></iframe> */}
            </div>
            <Tabs>
              <div className="course-single-tab">
                <TabList className={tabStyle}>
                  <Tab>
                    <button>{tab1}</button>
                  </Tab>
                  <Tab>
                    <button>{tab2}</button>
                  </Tab>
                  <Tab>
                    <button>{tab3}</button>
                  </Tab>
                  {/* <Tab><button>{tab4}</button></Tab> */}
                </TabList>

                <div className="tab-content" id="back-tab-content">
                  <TabPanel>
                    <div className="tab-pane">
                      <h3>About This Course</h3>
                      <p>{courseLesson}</p>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="tab-pane">
                      <h3>Reviews</h3>

                      <div className="row mt---40 mb----40">
                        <div className="col-lg-4">
                          <div className="five__number">
                            <em key={"index"}>
                              {courseReviewSummary &&
                                courseReviewSummary?.length > 0 &&
                                courseReviewSummary[0]?.reviews_summry_average?.toFixed(
                                  1
                                )}
                            </em>
                            <div className="back-ratings">
                              <i className="icon_star"></i>
                              <i className="icon_star"></i>
                              <i className="icon_star"></i>
                              <i className="icon_star"></i>
                              <i className="icon_star"></i>
                            </div>
                            <h6>({courseReviews.length} &nbsp;Ratings)</h6>
                          </div>
                        </div>
                        {courseReviewSummary.length > 1 && (
                          <div className="col-lg-8">
                            <div className="back-progress-bar">
                              <div className="skillbar-style2">
                                <div className="skill_inners">
                                  <span className="skillbar-title">
                                    5 stars
                                  </span>
                                  <div
                                    className="skillbars green-dark-bg"
                                    data-percent="100"
                                  >
                                    <p
                                      className="skillbar-bar"
                                      style={{ width: "100%" }}
                                    ></p>
                                  </div>
                                  <span>
                                    {courseReviewSummary[1].summary[4]
                                      ?.count_five || 0}
                                  </span>
                                </div>

                                <div className="skill_inners">
                                  <span className="skillbar-title">
                                    4 stars
                                  </span>
                                  <div
                                    className="skillbars green-dark-bg"
                                    data-percent="50"
                                  >
                                    <p
                                      className="skillbar-bar"
                                      style={{ width: "50%" }}
                                    ></p>
                                  </div>
                                  <span>
                                    {courseReviewSummary[1].summary[3]
                                      ?.count_four || 0}
                                  </span>
                                </div>

                                <div className="skill_inners">
                                  <span className="skillbar-title">
                                    3 stars
                                  </span>
                                  <div
                                    className="skillbars green-dark-bg"
                                    data-percent="30"
                                  >
                                    <p
                                      className="skillbar-bar"
                                      style={{ width: "30%" }}
                                    ></p>
                                  </div>
                                  <span>
                                    {courseReviewSummary[1].summary[2]
                                      ?.count_three || 0}
                                  </span>
                                </div>

                                <div className="skill_inners">
                                  <span className="skillbar-title">
                                    2 stars
                                  </span>
                                  <div
                                    className="skillbars green-dark-bg"
                                    data-percent="10"
                                  >
                                    <p
                                      className="skillbar-bar"
                                      style={{ width: "10%" }}
                                    ></p>
                                  </div>

                                  <span>
                                    {courseReviewSummary[1].summary[1]
                                      ?.count_two || 0}
                                  </span>
                                </div>

                                <div className="skill_inners">
                                  <span className="skillbar-title">1 star</span>
                                  <div
                                    className="skillbars green-dark-bg"
                                    data-percent="10"
                                  >
                                    <p
                                      className="skillbar-bar"
                                      style={{ width: "5%" }}
                                    ></p>
                                  </div>

                                  <span>
                                    {courseReviewSummary[1].summary[0]
                                      ?.count_one || 0}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      {courseReviews.map((review, index) => (
                        <Link to="#" className="post-author" key={index}>
                          <div className="info">
                            <div className="back-ratings">
                              {[...Array(5)].map((_, i) => (
                                <i
                                  style={{
                                    color:
                                      i < review.rating ? "#FE9415" : "#CCCCCC",
                                  }}
                                  className="icon_star"
                                  key={i}
                                ></i>
                              ))}
                            </div>
                            <h4 className="name">
                              {review.name}
                              &nbsp;
                              <span className="designation">{review.date}</span>
                            </h4>
                            <p>{review.review}</p>
                          </div>
                        </Link>
                      ))}

                      <div className="blog-form pt---30">
                        <h3>Write a Review</h3>
                        <p className="pb---15">
                          Your email address will not be published. Required
                          fields are marked *
                        </p>

                        <form id="contact-form" onSubmit={submitHandler}>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="back-input">
                                <input
                                  id="name"
                                  type="text"
                                  name="name"
                                  placeholder="Name"
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 pdl---15">
                              <div className="back-input">
                                <input
                                  id="email"
                                  type="email"
                                  name="email"
                                  placeholder="Email"
                                  required
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row pt---20">
                            <div className="col-lg-12">
                              <div className="back-input">
                                {[1, 2, 3, 4, 5].map((star) => (
                                  <i
                                    key={star}
                                    className={
                                      star <= formData.rating
                                        ? "icon_star filled"
                                        : "icon_star empty"
                                    }
                                    style={{
                                      cursor: "pointer",
                                      color:
                                        star <= (hovered || formData.rating)
                                          ? "#FE9415"
                                          : "gray",
                                    }}
                                    onClick={() => handleRatingChange(star)}
                                    onMouseEnter={() => setHovered(star)}
                                    onMouseLeave={() => setHovered(null)}
                                  ></i>
                                ))}
                              </div>
                            </div>
                            <div className="col-lg-12 pdl---15 pt---20">
                              <div className="back-input">
                                <textarea
                                  id="review"
                                  name="review"
                                  placeholder="Write your review"
                                  rows="6"
                                  required
                                ></textarea>
                              </div>
                            </div>
                          </div>
                          <div className="row pt---15">
                            <div className="col-lg-12">
                              <div className="back-input">
                                <button type="submit" className="back-btn">
                                  Submit Review
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="tab-pane">
                      <h3>FAQ</h3>
                      <Faq courseFaq={courseFaq} />
                    </div>
                  </TabPanel>
                </div>
              </div>
            </Tabs>
            <div className="react-course-filter related__course">
              <h3>Related Courses</h3>
              <div className="row react-grid">
             
  {Course.map((data, index) => {
    console.log('Rendering course:', data);
    return (
      <div className="single-studies col-lg-4 col-md-6 grid-item" key={index} >
        <Link to={`/course/${data.id}`} >
        <div className="inner-course">
          <div className="case-img">
          <Link to={`/course/${data.id}`}>
          <div className="cate-w">

              Relevent
            </div>
          </Link>
            
            
            <img
              src={`${BACKEND_URL}${data.course_image}`}
              alt="loading"
              className="course-img"
              style={{height:"7rem",width:"100%"}}
            />
          </div>
          <div className="case-content">
          <li style={{display:"flex",justifyContent:"left", position:"relative", right:"1rem",bottom:"1rem"}}>{data.course_duration}</li>
            <ul
              className="meta-course"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                
              }}
            
            >
              
              <li  style={{height:"3rem",paddingRight:"0rem"  ,position:"relative", right:"1rem",top:"1rem"}}>
                <h4  className="case-title">{data.course_name}</h4>
              </li>
             
            </ul>

            <ul className="react-ratings">
              <li className="price">{data.course_code}</li>
              <li className="price">${data.course_fee}</li>
            </ul>
          </div>
        </div>
      </Link>
      </div>
    );
  })}
  
</div>


            </div>
          </div>
          <div className="col-lg-4 md-mt-60">
            <div className="react-sidebar react-back-course2 ml----30">
              <div className="widget get-back-course">
                <ul className="price">
                  <li>{coursePrice} USD</li>
                </ul>
                <ul className="price__course">
                  {/* <li>
                    {" "}
                    <i className="icon_profile"></i> Instructor{" "}
                    <b>{courseAuthor}</b>
                  </li> */}
                  <li>
                    <i className="icon_tag_alt"></i> Subject <b>{courseName}</b>
                  </li>
                  {/* <li>
                    {" "}
                    <i className="icon_group"></i> Enrolled{" "}
                    <b>{courseEnrolled} students</b>
                  </li> */}
                  <li>
                    <i className="icon_clock_alt"></i> Duration{" "}
                    <b>{courseDuration}</b>
                  </li>
                  {/* <li>
                    <i className="icon_book_alt"></i> Lectures{" "}
                    <b>{courseLesson} lectures</b>
                  </li> */}
                  <li>
                    <i className="icon_map_alt"></i> Language <b> English</b>
                  </li>
                </ul>
                <Link
                  to={`/calender?courseId=${courseId}`}
                  className="start-btn"
                >
                  Enroll{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="feather feather-arrow-right"
                  >
                    <line x1="5" y1="12" x2="19" y2="12"></line>
                    <polyline points="12 5 19 12 12 19"></polyline>
                  </svg>
                </Link>
                {/* <div className="share-course">
                                     Pay Now <em>
                                         <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" 
                                         stroke="currentColor" stroke-width="2" stroke-linecap="round" 
                                         stroke-linejoin="round" className="feather feather-share-2">
                                             <circle cx="18" cy="5" r="3"></circle><circle cx="6" cy="12" r="3">
                                                 </circle><circle cx="18" cy="19" r="3">
                                                     </circle><line x1="8.59" y1="13.51" x2="15.42" y2="17.49">
                                                         </line><line x1="15.41" y1="6.51" x2="8.59" y2="10.49">
                                                             </line>
                                                             </svg></em>
                                     <span>                                         <Link to="#"><i aria-hidden="true" className="social_facebook"></i></Link>                                         <Link to="#"><i aria-hidden="true" className="social_twitter"></i></Link>                                         <Link to="#"><i aria-hidden="true" className="social_linkedin"></i></Link>                                     </span>                                 </div> */}{" "}
              </div>{" "}
              {/* <div className="widget react-categories-course">                                 <h3 className="widget-title">Course categories</h3>                                 <ul className="recent-category">                                     <li> <Link to="/course">Art & Design (6)</Link></li>                                     <li> <Link to="/course">Exercise (4)</Link></li>                                     <li> <Link to="/course">Photography (8)</Link></li>                                     <li> <Link to="/course">Environmental Sciences (3)</Link></li>                                     <li> <Link to="/course">Software Training (5)</Link></li>                                     <li> <Link to="/course">Software Development (2)</Link></li>                                     <li> <Link to="/course">Music (10)</Link></li>                                     <li> <Link to="/course">Material Design (2)</Link></li>                                     <li> <Link to="/course">Technology (7)</Link></li>                                 </ul>                             </div>                                 */}{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>{" "}
    </div>
  );
};

export default CourseDetailsMain;
