import React from 'react';
import Header from '../../components/Header';
import GoogleCalendar from './Calender';

const CalenderPage = () => {
    return (
        <>
            <Header
                parentMenu='home'
                topbarEnable='enable'
            />
                <hr />
        
            <GoogleCalendar/>
            
           
        </>
    );
}

export default CalenderPage;
