
import React, { useState } from 'react';
import { useLocation } from "react-router-dom";
import emailjs from 'emailjs-com';
import axios from 'axios';

const ContactForm = () => {
    const [reviews, setReviews] = useState([]);
    const location = useLocation();
    const courseURL = location.pathname.split("/");
    const courseId = Number(courseURL[2]);

    const submitHandler = (event) => {
       
        const form = event.target;
        const name = form.user_name.value;
        const email = form.user_email.value;
        const message = form.user_message.value;
        const phone = form.user_phone.value;

    
        console.log({ name, email, message, phone });
        emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form, 'YOUR_USER_ID')
            .then((result) => {
                console.log('Email sent:', result.text);
            }, (error) => {
                console.log('Email sending error:', error.text);
            });
        const data = {
            name,
            email,
            course_id: courseId,
            message,
            phone,
        };

        
        axios
            .post("http://backend.pioneerfirstaid.com.au/api/course_reviews/", data)
            .then((response) => {
                setReviews((prevReviews) => [response.data, ...prevReviews]);
                console.log("New review added:", response.data);
            })
            .catch((error) => {
                console.log(
                    "Error:",
                    error.response ? error.response.data : error.message
                );
            });
    };

    return (
        <div className="react-blog-form">
            <h2 className="contact-title">Questions? <br /> Feel free to contact us.</h2>
            <div id="blog-form" className="blog-form">
                <div id="form-messages"></div>
                <form id="contact-form" onSubmit={submitHandler}>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="back-input">
                                <input id="name" type="text" name="user_name" required placeholder="Name" />
                            </div>
                        </div>

                        <div className="col-lg-6 pdl-5">
                            <div className="back-input">
                                <input id="email" type="email" name="user_email" required placeholder="Email" />
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="back-input">
                                <input id="subject" type="text" name="user_subject" required placeholder="Subject" />
                            </div>
                        </div>

                        <div className="col-lg-6 pdl-5">
                            <div className="back-input">
                                <input id="phone" type="text" name="user_phone" required placeholder="Phone" />
                            </div>
                        </div>

                        <div className="col-lg-12">
                            <div className="back-textarea">
                                <textarea id="message" name="user_message" required placeholder="Message"></textarea>
                            </div>
                        </div>

                        <div className="col-lg-12">
                            <button type="submit" className="back-btn">Send Message
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-right">
                                    <line x1="5" y1="12" x2="19" y2="12"></line>
                                    <polyline points="12 5 19 12 12 19"></polyline>
                                </svg>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default ContactForm;
