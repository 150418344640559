import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { all_courses_for_homepage, getAPI } from "../../Api";

const MenuItems = (props) => {
  const [courses, setCourses] = useState([]);
  const [activeMenu, setActiveMenu] = useState(""); 

  useEffect(() => {
    getAllCourses();
  }, []);

  const getAllCourses = async () => {
    const data = await getAPI(all_courses_for_homepage);
    setCourses(data);
  };

  const location = useLocation();

  // Set active menu based on the current location path
  useEffect(() => {
    const currentPath = location.pathname.split("/")[1];
    setActiveMenu(currentPath);
  }, [location.pathname]);

  return (
    <>
      <li>
        <Link
          to="/"
          className={location.pathname === "/" ? "hash menu-active" : "hash"}
        >
          Home
        </Link>
      </li>

      <li className={activeMenu === "course" ? "has-sub menu-active" : "has-sub"}>
        <Link
          to="/course"
          className={activeMenu === "course" ? "hash menu-active" : "hash"}
        >
          Courses
          <span className="arrow "></span>
        </Link>

        <ul className={activeMenu === "course" ? "sub-menu sub-menu-open" : "sub-menu"}>
          {courses.map((course) => (
            <li key={course.id}>
              <Link to={`/course/${course.id}`}>
                {course.course_name}
              </Link>
            </li>
          ))}
        </ul>
      </li>

      <li>
        <Link
          to="/calender"
          className={location.pathname === "/calender" ? "hash menu-active" : "hash"}
        >
          Calendar
        </Link>
      </li>

      <li>
        <Link
          to="/about"
          className={location.pathname === "/about" ? "hash menu-active" : "hash"}
        >
          About Us
        </Link>
      </li>

      <li>
        <Link
          to="/contact"
          className={location.pathname === "/contact" ? "hash menu-active" : "hash"}
        >
          Contact
        </Link>
      </li>
    </>
  );
};

export default MenuItems;
