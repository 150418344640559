import React from "react";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from "react-accessible-accordion";

const Faq = ({ courseFaq }) => {

  return (
    <div class="accordion__wrapper1">
      <Accordion className="accordion" preExpanded={"a"}>
        {courseFaq.map((faq, index) => (
          <AccordionItem
            className="accordion-item"
            uuid={`a-${index}`}
            key={index}
          >
            <AccordionItemHeading className="accordion-button">
              <AccordionItemButton className="card-link">
                {faq.question}
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className="accordion-body">
              <p>{faq.answer}</p>
            </AccordionItemPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  );
};

export default Faq;
