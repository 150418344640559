import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Breadcrumb from "../../components/Breadcrumb/CourseBreadcrumbs";
import CourseDetailsMain from "./CourseDetailsMain";
import ScrollToTop from "../../components/ScrollTop";
import Logo from "../../assets/images/logos/logo2.png";
import { getAPI, single_course,all_courses_for_homepage,course_reviews } from "../../Api";
import { BACKEND_URL } from "../../global";

const CourseDetails = () => {
  const location = useLocation();
  const courseURL = location.pathname.split("/");
  const courseId = Number(courseURL[2]);

  const [course, setCourse] = useState([]);
  const [AllCourses, setAllCourses] = useState([]);
  const [faq, setFaq] = useState([]);
  const [review, setReview] = useState([]);
  const [reviewSummary, setreviewSummary] = useState([]);
  const [reviewSummarycount, setreviewSummarycount] = useState([]);

  useEffect(() => {
    getCourseById(courseId);
  }, [courseId]);
  const getCourseById = async (courseId) => {
    const data = await getAPI(single_course, courseId);
    const reviewsData = await getAPI(course_reviews, courseId);
    const AllCourses = await getAPI(all_courses_for_homepage)
    setAllCourses(AllCourses)
    setCourse(data);
    setFaq(data.faq);
    setReview( reviewsData.reviews)
    setreviewSummary(reviewsData.reviews_summary)
    setreviewSummarycount(reviewSummary[1])
    console.log(reviewSummarycount,"reviewSummarycount==========")
    console.log(reviewSummary,"reviewSummary==========")
    console.log(review,"review==============")
    console.log(AllCourses,"AllCourse----------------")
  };
  

  if (!course) {
    return <div>Course not found</div>;
  }

  // const courseFee = course?.course_fee?.toFixed(2).split('.');
  console.log("courseFee", course.course_fee);
  return (
    <div className="course-single">
      <Header
        parentMenu="course"
        menuCategoryEnable="enable"
        headerNormalLogo={Logo}
        headerStickyLogo={Logo}
      />

<hr/>

      <div className="react-wrapper">
        <div className="react-wrapper-inner">
          <Breadcrumb
            courseBannerImg={course.course_image}
            courseName={course.course_name}
            courseLesson={course.course_description}
          />

          <CourseDetailsMain
            Course={AllCourses}
            courseID={course.course_code}
            courseImg={BACKEND_URL + course.course_image}
            courseName={course.course_name}
            courseLesson={course.course_description}
            courseDuration={course.course_duration}
            coursePrice={course.course_fee}
            courseRegularPrice={course.course_fee}
            courseCode={course.course_code}
            courseFaq={faq}
            courseReviews={review}
            courseReviewSummary={reviewSummary}
            courseSummarycount={reviewSummarycount}

          />

          {/* scrolltop-start */}
          <ScrollToTop />
          {/* scrolltop-end */}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default CourseDetails;

















/*
<div className="row mt---40 mb----40">
                        <div className="col-lg-4">
                          <div className="five__number">
                            <em>4.5</em>
                            <div className="back-ratings">
                              <i className="icon_star"></i>
                              <i className="icon_star"></i>
                              <i className="icon_star"></i>
                              <i className="icon_star"></i>
                              <i className="icon_star"></i>
                            </div>
                            <h6>(4 Ratings)</h6>
                          </div>
                        </div>
                        <div className="col-lg-8">
                          <div className="back-progress-bar">
                            <div className="skillbar-style2">
                              <div className="skill_inners">
                                <span className="skillbar-title">5 stars</span>
                                <div
                                  className="skillbars green-dark-bg"
                                  data-percent="100"
                                >
                                  <p
                                    className="skillbar-bar"
                                    style={{ width: "100%" }}
                                  ></p>
                                </div>
                                <span>2</span>
                              </div>
                              <div className="skill_inners">
                                <span className="skillbar-title">4 stars</span>
                                <div
                                  className="skillbars green-dark-bg"
                                  data-percent="50"
                                >
                                  <p
                                    className="skillbar-bar"
                                    style={{ width: "50%" }}
                                  ></p>
                                </div>
                                <span>1</span>
                              </div>
                              <div className="skill_inners">
                                <span className="skillbar-title">3 stars</span>
                                <div
                                  className="skillbars green-dark-bg"
                                  data-percent="30"
                                >
                                  <p
                                    className="skillbar-bar"
                                    style={{ width: "30%" }}
                                  ></p>
                                </div>
                                <span>1</span>
                              </div>
                              <div className="skill_inners">
                                <span className="skillbar-title">2 stars</span>
                                <div
                                  className="skillbars green-dark-bg"
                                  data-percent="10"
                                >
                                  <p
                                    className="skillbar-bar"
                                    style={{ width: "10%" }}
                                  ></p>
                                </div>
                                <span>0</span>
                              </div>
                              <div className="skill_inners">
                                <span className="skillbar-title">1 stars</span>
                                <div
                                  className="skillbars green-dark-bg"
                                  data-percent="0"
                                >
                                  <p
                                    className="skillbar-bar"
                                    style={{ width: "0" }}
                                  ></p>
                                </div>
                                <span>0</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
*/