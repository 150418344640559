import { useState } from "react";
import ModalVideo from "react-modal-video";
import { Link } from "react-router-dom";
import Slider from "react-slick";

import sliderImg1 from "../../assets/images/Banner/1.png";
// import sliderImg2 from "../../assets/images/slider/2.jpg";
// import sliderImg11 from "../../assets/images/slider/11.jpg";
// import sliderImg12 from "../../assets/images/slider/12.jpg";

const HomeSlider = () => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(!isOpen);

  const sliderSettings = {
    dots: false,
    arrows: true,
    infinite: true,
    margin: 0,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          arrows: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  return (
    <>
      <div className="react-slider-part">
        <ModalVideo
          channel="youtube"
          isOpen={isOpen}
          videoId="OKr9lH2TAow"
          onClose={() => {
            openModal();
          }}
        />
        <div className="home-sliders home2">
          {/* <Slider {...sliderSettings}> */}
          <div className="single-slide">
            <div className="slider-img">
              <img className="desktop" src={sliderImg1} />
              {/* <img className="mobile" src={sliderImg11} alt="Slider Image 1" /> */}
            </div>
            <div className="container">
              <div className="slider-content">
                {/* <div className="content-part">
                  <span
                    className="slider-pretitle wow animate__fadeInUp"
                    data-wow-duration="1s"
                  >
                    Learn, Apply, Succeed – All in One Day
                  </span>
                  <h2
                    className="slider-title wow animate__fadeInUp"
                    data-wow-duration="1s"
                  >
                    Empower Your Skills <br />
                    in a Day
                  </h2>
                </div> */}
              </div>
              <div
                className="event__video-btn--play wow animate__fadeInUp"
                data-wow-duration="1.4s"
              >
                <Link
                  to="#"
                  className="event__video-btn--play-btn custom-popup"
                  onClick={() => {
                    openModal();
                  }}
                >
                  <i className="arrow_triangle-right"></i>
                  <em>
                    Watch Video <br />
                    Intro
                  </em>
                </Link>
              </div>
            </div>
          </div>
          <div className="single-slide">
            <div className="slider-img">
              {/* <img className="desktop" src={sliderImg2} alt="Slider Image 1" /> */}
              {/* <img className="mobile" src={sliderImg12} alt="Slider Image 1" /> */}
            </div>
            <div className="container">
              <div className="slider-content">
                <div className="content-part">
                  {/* <span className="slider-pretitle wow animate__fadeInUp" data-wow-duration="1s">Learn, Apply, Succeed – All in One Day</span> */}
                  {/* <h2 className="slider-title wow animate__fadeInUp" data-wow-duration="1s">
                                        Empower Your Skills <br />in a Day
                                            
                                        </h2> */}
                  <div
                    className="slider-btn wow animate__fadeInUp"
                    data-wow-duration="1.2s"
                  ></div>
                </div>
              </div>
              <div
                className="event__video-btn--play wow animate__fadeInUp"
                data-wow-duration="1.4s"
              >
                <Link
                  to="#"
                  className="event__video-btn--play-btn custom-popup"
                  onClick={() => {
                    openModal();
                  }}
                >
                  <i className="arrow_triangle-right"> </i>
                  <em>
                    Watch Video <br />
                    Intro
                  </em>
                </Link>
              </div>
            </div>
          </div>
          {/* </Slider> */}
        </div>
      </div>
    </>
  );
};

export default HomeSlider;
