import React, { useState } from "react";
import Slider from "react-slick";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

import SingleTestimonial from "../../components/Testimonial/SingleTestimonial";
import testiImg1 from '../../assets/images/Archive4/BlueLIneShirt-girl.jpg';
import testiImg2 from '../../assets/images/Archive4/OrangeShirt-girl.jpg';
import testiImg3 from '../../assets/images/Archive4/checkShit-girl.jpg';
import testiImg4 from '../../assets/images/Archive4/Buisnessman.jpg';
import testiImg5 from '../../assets/images/Archive4/man.jpg';
import testiImg6 from '../../assets/images/Archive4/myasthenia-230x260.jpg';
import comaImg from "../../assets/images/testimonial/coma.png";

import countIcon1 from "../../assets/images/counter/1.png";
import countIcon2 from "../../assets/images/counter/2.png";
import countIcon3 from "../../assets/images/counter/3.png";
import countIcon4 from "../../assets/images/counter/4.png";

const Testimonial = () => {
  const sliderSettings = {
    dots: false,
    arrows: true,
    infinite: true,
    margin: 0,
    centerMode: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          arrows: true,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };

  const [state, setState] = useState(true);

  const counters = [
    {
      countNum: 180,
      countTitle: "Total Student",

      countIcon: countIcon1,
    },
    {
      countNum: 100,
      countTitle: "Successfully Trained",

      countIcon: countIcon2,
    },
    {
      countNum: 8,
      countTitle: "Course Offerings",

      countIcon: countIcon3,
    },
    {
      countNum: 13,
      countTitle: "Total Trainers",

      countIcon: countIcon4,
    },
  ];

  return (
    <div className="student_satisfaction-section pt---110 pb---120">
      <div className="container">
        <div className="react__title__section-all pb---30">
          <div className="row">
            <div className="col-md-12 text-center">
              <h6 className="wow animate__fadeInUp" data-wow-duration="0.3s">
                Student Satisfaction
              </h6>
              <h2
                className="react__tittle wow animate__fadeInUp"
                data-wow-duration="0.5s"
              >
                Student Community <br />
                Feedback
              </h2>
            </div>
          </div>
        </div>
        <div className="feedreact-slider owl-carousel">
          <Slider {...sliderSettings}>
            <div className="event__card">
              <div className="event__card--content">
                <div className="event__card--content-area">
                  <SingleTestimonial
                    itemClass="single-client"
                    itemImg={testiImg1}
                    Title="Sarah Mitchell"
                    Designation="Student"
                    Desc="The course was incredibly informative and easy to follow. I now feel confident in handling emergencies. Highly recommend Pioneer First Aid!"
                    ratingCount="4.9"
                    reviewCount="14"
                    comaImg={comaImg}
                  />
                </div>
              </div>
            </div>
            <div className="event__card">
              <div className="event__card--content">
                <div className="event__card--content-area">
                  <SingleTestimonial
                    itemClass="single-client"
                    itemImg={testiImg4}
                    Title=" David Thompson"
                    Designation="Student"
                    Desc="Excellent training with practical, real-world examples. The instructors were knowledgeable and patient. A must for anyone!"

                    ratingCount="4.9"
                    reviewCount="14"
                    comaImg={comaImg}
                  />
                </div>
              </div>
            </div>
            <div className="event__card">
              <div className="event__card--content">
                <div className="event__card--content-area">
                  <SingleTestimonial
                    itemClass="single-client"
                    itemImg={testiImg3}
                    Title="Emily Rogers"
                    Designation="Student"
                    Desc="I learned so much in just a few hours. The trainers were engaging and made complex topics simple. Truly invaluable training!"

                    ratingCount="4.9"
                    reviewCount="14"
                    comaImg={comaImg}
                  />
                </div>
              </div>
            </div>
            <div className="event__card">
              <div className="event__card--content">
                <div className="event__card--content-area">
                  <SingleTestimonial
                    itemClass="single-client"
                    itemImg={testiImg5}
                    Title="James Carter"
                    Designation="Student"
                    Desc="Great experience! The hands-on approach really helped solidify the techniques. I feel much more prepared for emergencies now."

                    ratingCount="4.9"
                    reviewCount="14"
                    comaImg={comaImg}
                  />
                   
                  
                </div>
              </div>
            </div>
            <div className="event__card">
              <div className="event__card--content">
                <div className="event__card--content-area">
                  <SingleTestimonial
                    itemClass="single-client"
                    itemImg={testiImg2}
                    Title=" Laura Bennett"
                    Designation="Student"
                    Desc="The course exceeded my expectations. The instructors made it fun and informative. I now feel empowered to help in critical situations!"
                    

                    ratingCount="4.9"
                    reviewCount="14"
                    comaImg={comaImg}
                  />
                   
                  
                </div>
              </div>
            </div>
            <div className="event__card">
              <div className="event__card--content">
                <div className="event__card--content-area">
                  <SingleTestimonial
                    itemClass="single-client"
                    itemImg={testiImg6}
                    Title="Michael Evans"
                    Designation="Student"
                    Desc="Pioneer First Aid delivered top-notch training! The materials were clear, and the practical sessions were extremely useful."


                    ratingCount="4.9"
                    reviewCount="14"
                    comaImg={comaImg}
                  />
                   
                  
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
      {counters && (
        <div className="count__area2 pb---100">
          <div className="container">
            <ul className="row">
              {counters.map((counter, num) => (
                <li
                  key={num}
                  className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6"
                >
                  <div className="count__content">
                    <div className="icon">
                      <img src={counter.countIcon} alt="image" />
                    </div>
                    <div className="text">
                      <CountUp
                        start={state ? 0 : counter.countNum}
                        end={counter.countNum}
                        duration={10}
                        onEnd={() => setState(false)}
                      />
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <span ref={countUpRef} />
                          <span className="count__content-title counter">
                            {counter.countNum}
                          </span>
                        </VisibilitySensor>
                      )}
                      <em>{counter.countSubtext}</em>
                      <p className="count__content">{counter.countTitle}</p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default Testimonial;
