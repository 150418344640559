import React from 'react';
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap';


const SingleCourse = (props) => {
    const { itemClass, courseID, courseImg, courseTitle, courseName,  coursePrice,courseDuration,courseCode } = props;
	return(
        <Link to={`/course/${courseID}`}>
        <div className={itemClass ? itemClass : 'course__item mb-30 '}>
            <div className="course__thumb" >
                <Link to={`/course/${courseID}`}>
                    <img src={courseImg } alt="image" style={{height:"9rem",width:"100%"}}/>
                </Link>
            </div>
            <div className="course__inner">

                <ul style={{display:"flex" , justifyContent:"space-between"}}>
                    <li>{courseName ? courseName : 'UX Design'}</li>
                    <li>{courseDuration ? courseDuration : '4'} </li>
                </ul>
                <h3 className="react-course-title " style={{height:"2rem",}} >
                 
                        {courseTitle}<br/>
                </h3>

                
                
                <div className="course__card-icon d-flex align-items-center">
                    <div className="course__card-icon--1">
                    <span  >{courseCode}</span>
                   
                  
                    </div>                                            
                    <div className="react__user">
                    <span>
                    ${coursePrice ? coursePrice :"22" }
                    </span>
                    </div>
                </div>
            </div>                                    
        </div>
        </Link>

	)
}

export default SingleCourse