import React, { useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import '@sweetalert2/themes/material-ui/material-ui.css';
import { BACKEND_URL } from '../../global';
import { useNavigate } from 'react-router-dom';


const getAPI = async (name, email, phone, location) => {
    try {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${BACKEND_URL}/api/cancel/?student_name=${name}&student_email=${email}&student_phone=${phone}&course_location_id=${location}`,
            headers: {

            },
        };
        const response = await axios.request(config);
        if (response.status === 200) return response.data;
    } catch (err) {
        console.error(err);
        return { status: 'error', message: 'Something went wrong' };
    }
};

const FailedStatusPage = () => {

    const navigate = useNavigate();
    useEffect(() => {
        handlePayment('success')
        const fetchData = async () => {
            const userInfo = window.localStorage.getItem("userInfo") ? JSON.parse(window.localStorage.getItem("userInfo")) : null;
            const { name, email, phone, location } = userInfo;
            if (name && email && phone && location) {
                await getAPI(name, email, phone, location).then((result) => {
                    setTimeout(() => {
                        Swal.close()
                        navigateHome();
                    }, 4000)
                });
            }
        };

        fetchData();
    }, []);
    const navigateHome = () => {
        navigate('/', { replace: true });
    };
    const handlePayment = (status) => {
        Swal.fire({
            title: 'Unsuccessful',
            text: 'Your payment was unsuccessful.',
            icon: 'error',
            showCloseButton: false,
            showConfirmButton: false
        })
    };
    return (
        <div>
        </div>
    );
};

export default FailedStatusPage;
