import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/SectionTitle';
import SingleCourse from '../../components/Course/SingleCourse';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { all_courses_for_homepage, getAPI } from '../../Api';
import { BACKEND_URL } from '../../global';


const Course = () => {
    const [courses, setCourses] = useState([]);
    useEffect(() => {
        getAllCourses()
    }, [])
    const getAllCourses = async () => {
        const data = await getAPI(all_courses_for_homepage)
        setCourses(data)
    }

    console.log("courses", courses)
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 2
    };

    return (
        <div className="popular__course__area pt---100 pb---100">
            <div className="container">
                <SectionTitle Title="Popular Courses" />
                <div className="row">
                    <Slider {...settings}>
                        {courses && courses.map((data, index) => {
                            return (
                                <div key={index} className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 wow animate__fadeInUp" data-wow-duration="0.3s">
                                    <SingleCourse
                                        courseID={data.id}
                                        courseImg={BACKEND_URL + data.course_image}
                                        courseTitle={data.course_name}
                                        courseDuration={data.course_duration}
                                        courseName="On Site"
                                        coursePrice={data.course_fee}
                                        courseCode={data.course_code}
                                    />
                                </div>
                            )
                        })}
                    </Slider>
                </div>
                <div className="text-center">
                    <Link to="/course" className="view-courses"> View All Courses <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></Link>
                </div>
            </div>
        </div>
    );
}

export default Course;
