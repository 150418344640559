import React from 'react';
import Slider from "react-slick";

import SectionTitle from '../../components/SectionTitle'
import SingleTestimonial from '../../components/Testimonial/SingleTestimonial';
import testiImg1 from '../../assets/images/Archive4/BlueLIneShirt-girl.jpg';
import testiImg2 from '../../assets/images/Archive4/OrangeShirt-girl.jpg';
import testiImg3 from '../../assets/images/Archive4/checkShit-girl.jpg';
import testiImg4 from '../../assets/images/Archive4/Buisnessman.jpg';
import testiImg5 from '../../assets/images/Archive4/man.jpg';
import testiImg6 from '../../assets/images/Archive4/myasthenia-230x260.jpg';
import comaImg from '../../assets/images/testimonial/coma.png';

const Testimonial = () => {

    const testimonialSettings = {
        dots: false,
        arrows: true,
        infinite: true,
        centerMode: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 1,
                }
            },
            {

                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    arrows: false,
                }
            }
        ]
    };

    return (
        <>
            <div className="react-clients react-clientso home-testimonial pt---90 pb---120">
                <div className="container">
                    <SectionTitle Title="What our student saying" />
                    <div className="client-slider wow animate__fadeInUp" data-wow-duration="0.3s">
                        <Slider {...testimonialSettings}>
                            <SingleTestimonial
                                itemClass="single-client"
                                itemImg={testiImg1 }
                                Title="Sarah Mitchell"
                                Designation="Student"
                                Desc="The course was incredibly informative and easy to follow. I now feel confident in handling emergencies. Highly recommend Pioneer First Aid!"

                                ratingCount='4.9'
                                reviewCount='14'
                                comaImg={comaImg}
                            />
                            <SingleTestimonial
                                itemClass="single-client"
                                itemImg={testiImg4 }
                                Title="David Thompson"
                                Designation="Student"
                                Desc="Excellent training with practical, real-world examples. The instructors were knowledgeable and patient. A must for anyone!"

                                ratingCount='4.9'
                                reviewCount='14'
                                comaImg={comaImg}
                            />
                            <SingleTestimonial
                                itemClass="single-client"
                                itemImg={testiImg2 }
                                Title="Emily Rogers"
                                Designation="Student"
                                Desc="I learned so much in just a few hours. The trainers were engaging and made complex topics simple. Truly invaluable training!"

                                ratingCount='4.9'
                                reviewCount='14'
                                comaImg={comaImg}
                            />
                            <SingleTestimonial
                                itemClass="single-client"
                                itemImg={testiImg5 }
                                Title="James Carter"
                                Designation="Student"
                                Desc=
                                "Great experience! The hands-on approach really helped solidify the techniques. I feel much more prepared for emergencies now."
                                
                                
                                ratingCount='4.9'
                                reviewCount='14'
                                comaImg={comaImg}
                            />
                            <SingleTestimonial
                                itemClass="single-client"
                                itemImg={testiImg3 }
                                Title="Laura Bennett"
                                Designation="Student"
                                Desc="The course exceeded my expectations. The instructors made it fun and informative. I now feel empowered to help in critical situations!"

                                ratingCount='4.9'
                                reviewCount='14'
                                comaImg={comaImg}
                            />
                            <SingleTestimonial
                                itemClass="single-client"
                                itemImg={testiImg6 }
                                Title="Michael Evans"
                                Designation="Student"
                                Desc="Pioneer First Aid delivered top-notch training! The materials were clear, and the practical sessions were extremely useful."


                                ratingCount='4.9'
                                reviewCount='14'
                                comaImg={comaImg}
                            />
                        </Slider>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Testimonial