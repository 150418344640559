import "../../assets/scss/_menu.scss";
import React, { useState, useEffect } from "react";
import { Calendar, dateFnsLocalizer, momentLocalizer } from "react-big-calendar";
import { format, parse, startOfWeek, getDay } from "date-fns";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Modal, ModalFooter, ModalHeader, ModalBody, Button, Form, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import locales from "date-fns/locale/en-US";
import { MultiSelect } from "react-multi-select-component";


import {
  Link,
  useLocation
} from "react-router-dom";
import { enroll_in_course, getAPI, postAPI, single_course, all_courses_for_calendar } from "../../Api";
import moment from "moment";
import { BACKEND_URL } from "../../global";


const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales: { "en-US": locales },
});

const CustomEvent = ({ event }) => {
  return (
    <div style={{
      textAlign: 'center',
      padding: '10px',
      backgroundColor: '#3788d8',
      color: '#ffffff',
      fontSize: '12px'
    }}>
      Total Seats: {event.course_name}
      <br />
       
    </div>
  )
};


const formatEvents = (data, title = "test", backgroundColor) => {
  return data
    .map((event) => {
      const {
        id,
        location_details,
        address,
      } = event;
      return location_details
        .map((location) => {

          const { course_date, course_time, seats_left, total_seats, course_name, } = location;
          const start = moment(`${course_date} ${course_time}`).toDate()
          const end = moment(`${course_date} ${course_time}`).toDate()
          return {
            start,
            end,
            course_date,
            course_time,
            seats_left,
            total_seats,
            location_id: id,
            location: event.location,
            address,
            title: title,
            course_name: title,
            backgroundColor
          };
        })
    }).flat()
};

const formatEventsForAllCourses = (data, course=null) => {
  return data
    .map((location) => {
      const { course_date, course_time, seats_left, total_seats, } = location;
      const start = moment(`${course_date} ${course_time}`).toDate()
      const end = moment(`${course_date} ${course_time}`).toDate()
      let course_details = null
      if (location?.course_details && location?.course_details[0]) {
        const { course_image, course_name, course_description, id: course_id } = location.course_details[0];
        return {
          start,
          end,
          course_date,
          course_time,
          seats_left,
          total_seats,
          location_id: location.id,
          location: location.location,
          address: location.address,
          course_image,
          course_id,
          course_name,
          title: course_name,
          course_description,
          backgroundColor: location.course_color_code
        };
      } else {
        const { course_image, course_name, course_description } = course;
        return {
          start,
          end,
          course_date,
          course_time,
          seats_left,
          total_seats,
          location_id: location.location_id,
          location: location.location,
          address: location.address,
          course_image,
          course_id: course_details,
          course_name,
          title: course_name,
          course_description,
          backgroundColor:course.course_color_code
        };
      }


    })

};
const listDownCourses = (data) => {
  return data
    .map((location) => {
      const { course_image, course_name, course_description, id: course_id } = location.course_details[0];
      return {
        course_id,
        course_name,

      };
    })

};

function GoogleCalendar() {
  const query = useQuery()
  const courseId = query.get("courseId")
  console.log("courseId", courseId)
  const [allEvents, setAllEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [secondModalIsOpen, setSecondModalIsOpen] = useState(false);
  const [selectedDateEvents, setSelectedDateEvents] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedLocations, setSelectedLocation] = useState(undefined);
  const [selectedCoursesValues, setSelectedCoursesValues] = useState(undefined);


  const [seatInputs, setSeatInputs] = useState({});
  const [bookingSeats, setBookingSeats] = useState({});
  const [enrolledCourse, setEnrolledCourse] = useState(null);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [course, setCourse] = useState([]);
  const [uniqueCourses, setUniqueCourses] = useState([]);
  const [calender, setCalender] = useState([]);
  const [courseList, setCourseList] = useState([]);

  const [submitError, setSubmitError] = useState({});

  useEffect(() => {
    if (courseId) {
      getCourseById(courseId)
    } else {
      getAllCourses()
    }
  }, [courseId])

  useEffect(() => {
    let all = [...allEvents]
    if (selectedLocations !== "undefined" && selectedLocations !== undefined) {
      all = all.filter(f => f.location === selectedLocations)
    }
    if (selectedCoursesValues !== "undefined" && selectedCoursesValues !== undefined) {
      all = all.filter(f => f.course_name === selectedCoursesValues)
    }
    showSameDayCourses(all)

  }, [selectedLocations, selectedCoursesValues])
  const getCourseById = async (courseId) => {
    const data = await getAPI(single_course, courseId)
    setCourse(data)
    const formattedEvents = formatEvents(data.all_locations, data.course_name, data.course_color_code);
    const events = formatEventsForAllCourses(formattedEvents, data)
    setAllEvents(events);
    showSameDayCourses(events)
    console.log("formattedEvents", formattedEvents)
    const unique = [...Array.from(new Set(data?.all_locations.map(l => l.location)))]
  

    setUniqueCourses([...unique.map(c => ({ label: c, value: c }))])
  }
  const getAllCourses = async () => {
    const data = await getAPI(all_courses_for_calendar);
    setCourse(data)
    const courses = listDownCourses(data)
    const unique = [...Array.from(new Set(data.map(l => l.location)))]

    const uniqueCourses = [...Array.from(new Set(courses.map(l => l.course_name)))]
    setCourseList([...uniqueCourses.map(c => ({ label: c, value: c }))])
    setUniqueCourses([...unique.map(c => ({ label: c, value: c }))])
    const events = formatEventsForAllCourses(data)

    setAllEvents(events)
    showSameDayCourses(events)
  }


  const showSameDayCourses = (events) => {
    let removeSameCourse = []
    for (let index = 0; index < events.length; index++) {
      const element = events[index];
      const found = removeSameCourse.find(f => f.course_id === element.course_id && f.course_date === element.course_date)
      if (!found) {
        removeSameCourse.push({ ...element, sameDay: true })
      }
    }
    setFilteredEvents(removeSameCourse)
  }

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedDateEvents([]);
    setSelectedDate(null);
  };

  const closeSecondModal = () => {
    setSecondModalIsOpen(false);
  };



  const handleCourseSelect = (e) => {
    const value = e.target.value
    setSelectedLocation(value)
  };
  const handleSelectEvent = ({ start, course_date, course_id }) => {
    let tempEvents = [...allEvents]
    if (selectedLocations !== "undefined" && selectedLocations !== undefined) {
      tempEvents = tempEvents.filter(f => f.location === selectedLocations)
    }
    const eventsOnSameDate = tempEvents.filter(
      (e) => {
        return e.course_date === course_date && e.course_id === course_id
      }
    );
    let sameDayCourse = []
    for (let index = 0; index < eventsOnSameDate.length; index++) {
      const element = eventsOnSameDate[index];
      const found = sameDayCourse.findIndex(f => f.course_id === element.course_id && f.course_date === element.course_date)
      if (found !== -1) {
        const allTimings = [...sameDayCourse[found].allTimings, {
          course_time: element.course_time,
          location: element.location,
          total_seats: element.total_seats,
          seats_left: element.seats_left,
          location_id:element.location_id,
          course_date: element.course_date,
          address:element.address

        }]
        sameDayCourse[found] = { ...sameDayCourse[found], allTimings }
      } else {
        sameDayCourse.push({
          ...element, allTimings: [{
            course_time: element.course_time,
            location: element.location,
            total_seats: element.total_seats,
            seats_left: element.seats_left,
            location_id: element.location_id,
            address:element.address
          }]
        })

      }
      console.log("sameDayCourse", sameDayCourse)
    }
    if (sameDayCourse.length > 0) {
      setSelectedDateEvents(sameDayCourse);
      setSelectedDate(start);
      setModalIsOpen(true);
    }
  };
  const handleCourseSelectHandler = (e) => {
    const value = e.target.value
    setSelectedCoursesValues(value)
  };
  const handleSeatChange = (event, index) => {
    const { name, value } = event.target;
    setSeatInputs((prevState) => ({
      ...prevState,
      [index]: {
        ...prevState[index],
        [name]: value,
      },
    }));
  };


  const handleEnrollClick = (event, index) => {
    setEnrolledCourse({ ...event, bookedSeats: bookingSeats[index]?.bookedSeats || 0, index });
    closeModal();
    setSecondModalIsOpen(true);
  };
  const handleEnrollClickForTiming = (event, location_id) => {
    setEnrolledCourse({ ...event, bookedSeats: 1, location_id });
    closeModal();
    setSecondModalIsOpen(true);
  };

  const handleEnrollCourse = async () => {
    let data = new FormData();
    const bookedSeats = Number(enrolledCourse.bookedSeats)
    if (name && email && phone && enrolledCourse.location_id) {
      data.append('course_location_id', enrolledCourse.location_id);
      data.append('quantity', bookedSeats > 0 ? bookedSeats : 1);
      data.append('student_name', name);
      data.append('student_email', email);
      data.append('student_phone', phone);
      const response = await postAPI(enroll_in_course, data)
      window.localStorage.setItem("userInfo", JSON.stringify({
        name, email, phone, location: enrolledCourse.location_id
      }))
      if (response.url) {
        window.open(response.url, '_blank',);
      }
      setSecondModalIsOpen(false);
    } else {
      setSubmitError({ error: true, message: "Please fill all fields" });
    }

  }

  

  const eventStyleGetter = (event, start, end, isSelected) => {
    const sameStyle = {
      backgroundColor: event.backgroundColor,
      opacity: 0.8,
      color: 'white',
      border: '1px solid black',
      borderRadius: '5px',
      padding: '10px',
      fontSize: '12px',
      fontWeight: '600',
      width: '100%',
    }
    if (event?.sameDay) {
      return {
        style: {
          ...sameStyle,
          marginTop: '20px',

        }
      };
    } else {
      return {
        style: {
          ...sameStyle
        }
      }
    }
  };
  return (
    <div className="row mx-2">
      <div className="col-12 col-lg-8 mx-auto">
        <div style={{ position: 'relative' }} className=" d-flex flex-row align-items-center justify-content-between mx-auto gap-2">
          {
            !courseId && <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "20px",
                width: '100%',
                zIndex: 10
              }}
            >
              <Form.Group
                className="d-flex flex-column justify-content-center align-items-start w-100"
              >
                <Form.Label className="text-start">
                  Select Course
                </Form.Label>
                <Form.Select
                  type="select"
                  name="totalSeats"
                  value={
                    selectedCoursesValues
                  }
                  onChange={handleCourseSelectHandler}
                >
                  <option value={"undefined"}>{"Select course"}</option>)

                  {
                    courseList.map((item => {
                      return (
                        <option value={item.value}>{item.label}</option>)
                    }))
                  }
                </Form.Select>
              </Form.Group>
            </div>
          }
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "20px",
              width: courseId ? '50%' : '100%',
              zIndex: 10


            }}
          >
            <Form.Group
              className="d-flex flex-column justify-content-center align-items-start  w-100"
            >
              <Form.Label className="text-start">
                Select Location
              </Form.Label>
              <Form.Select
                type="select"
                name="totalSeats"
                value={
                  selectedLocations
                }
                onChange={handleCourseSelect}
              >
                <option value={"undefined"}>{"Select location"}</option>)
                {
                  uniqueCourses.map((item => {
                    return (
                      <option value={item.value}>{item.label}</option>)
                  }))
                }
              </Form.Select>
            </Form.Group>
          </div>
        </div>
        <div className="calendar-container">
          <Calendar
            onSelectEvent={handleSelectEvent}
            localizer={localizer}
            events={filteredEvents}
            views={["month"]}
            eventPropGetter={eventStyleGetter}
            enableAutoScroll={true}
          />
        </div>
        <Modal show={modalIsOpen} onHide={closeModal} size="lg" centered>
          <Modal.Header>
            <Modal.Title className="text-center mx-auto">{courseId ? course.course_name : selectedDateEvents?.length > 0 ? selectedDateEvents[0].course_name : ""} Timings </Modal.Title>
          </Modal.Header>

          <ModalBody>
            {selectedDateEvents.length > 0 && (
              selectedDateEvents.map((seleted, index) => (
                <div className="media modal-div">
                  <div className="d-flex flex-column  align-items-start">
                    <img
                      src={BACKEND_URL + `${courseId ? course.course_image : seleted.course_image}`}
                      alt={courseId ? course.course_name : seleted.course_name}
                      className=" w-100 p-0 m-0" style={{height:'300px'}}
                    />
                    <div className=" d-flex flex-row w-100 justify-content-end align-items-end mt-4">
                      <Link to={`/course/${seleted.course_id}`} target="_blank" className="px-1 mx-2" onClick={closeSecondModal}>
                        View Course
                      </Link>
                    </div>
                    <div style={{maxHeight:'270px', width:'100%',overflow:'auto'}}>
                    <table class="table table-striped" >
                    <thead>
                        <tr>
                          <th>Time</th>
                          <th>Location</th>
                          <th>Total Seats</th>
                          <th>Left Seats</th>
                          <th style={{width:'100px'}}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          seleted?.allTimings.map((obj) => (
                           <>
                            <tr>
                              <td>
                                {obj.course_time}
                              </td>
                              <td>
                                {obj.location}
                              </td>
                              <td>
                                {obj.total_seats}
                              </td>
                              <td>
                                {obj.seats_left}
                              </td>
                              <td>
                              <Button className="px-3" onClick={() => handleEnrollClickForTiming(seleted, obj.location_id)}>
                              Enroll
                            </Button>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={5} className="text-start"><span className="fw-bold mr-2">Address:</span> {obj.address}</td>
                            </tr>
                            </>
                          ))
                        }
                        
                      </tbody>
                    </table>
                    </div>
                   
                  </div>

                </div>
              ))
            )
            }
          </ModalBody>



        </Modal>
        <Modal show={secondModalIsOpen} onHide={closeSecondModal} size="lg" centered >

          <ModalBody>
            {
              enrolledCourse && (
                <div className="media modal-div">
                  <div className="d-flex  align-items-start">
                    <img
                      src={BACKEND_URL + `${courseId ? course.course_image : enrolledCourse.course_image}`}
                      alt={courseId ? course.course_name : enrolledCourse.course_name}
                      className=" my-auto mx-1 py-2"
                      style={{ height: '240px !important', width: '50%' }}
                    />
                    <div className="">
                      <div className="d-flex flex-row w-100 justify-content-center align-items-start mt-4">
                        <h4 className="mt-0 modal-title text-nowrap text-uppercase">{courseId ? course.course_name : enrolledCourse.course_name}</h4>
                      </div>
                      <Card className="w-100">
                        <Card.Body className="d-flex flex-column justify-content-between w-100">
                          <div className="d-flex flex-column w-100">
                            <span>
                              <span className="fw-bold ">Date</span>:<span className="mx-1">{enrolledCourse.course_date}</span>
                            </span>
                            <span className=""><span className="fw-bold">Location:</span><span className="mx-1">{enrolledCourse.location}</span></span>


                          </div>
                          <div className="d-flex flex-row justify-content-between w-100 gap-5">
                            <div className="d-flex flex-column">
                              <span>
                                <span className="fw-bold">Time</span>:<span className="mx-1">{enrolledCourse.course_time}</span>
                              </span>
                              <div>
                                <span className="fw-bold ">Total Seats:</span><span className="mx-1">{enrolledCourse.total_seats}</span>
                              </div>


                            </div>
                            <div className="d-flex flex-column">
                              <span>
                                <div ><span className="fw-bold">Left Seats:</span><span className="mx-1">{enrolledCourse.seats_left}</span></div>
                              </span>
                              <div ><span className="fw-bold ">Price:</span> <span className="mx-1">{"$seleted.price"}</span></div>
                            </div>
                          </div>

                        </Card.Body>
                      </Card>


                    </div>
                  </div>

                </div>
              )
            }

          </ModalBody>



          <div className="w-100 px-4">
            <div className="enroll">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => {
                  setSubmitError({ error: false, message: '' })
                  setEmail(e.target.value)
                }}
                required
              />
            </div>
            <div className="enroll">
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => {
                  setSubmitError({ error: false, message: '' })

                  setName(e.target.value)
                }}
                required
              />
            </div>
            <div className="enroll">
              <label htmlFor="phone">Phone:</label>
              <input
                type="tel"
                id="phone"
                value={phone}
                onChange={(e) => {
                  setSubmitError({ error: false, message: '' })
                  setPhone(e.target.value)
                }}
                required
              />
            </div>
          </div>
          <div>
            {submitError.error && (<div>
              <p className="text-danger">{submitError.message}</p>
            </div>)}
          </div>



          <ModalFooter>
            <Button variant="secondary" onClick={closeSecondModal}>
              Close
            </Button>
            <Button onClick={handleEnrollCourse}>
              Register
            </Button>
          </ModalFooter>
        </Modal>

      </div>

    </div>
  );
}

export default GoogleCalendar;

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}



// selectedDateEvents.length > 0 && (
//   selectedDateEvents.map((seleted,index) => (
//     <div className="media modal-div">
//       <div className="d-flex flex-column  align-items-start">
//         <img
//           src={BACKEND_URL + `${courseId ? course.course_image : seleted.course_image}`}
//           alt={courseId ? course.course_name : seleted.course_name}
//           className="my-auto mx-1 py-2"
//           style={{ height: 'auto',width:'100%' }}
//         />
//         <div className="">
//           <div className="d-flex flex-row w-100 justify-content-between align-items-start mt-4">
//             <h4 className="mt-0 modal-title text-nowrap text-uppercase">description:{courseId ? course.course_name : seleted.course_name}</h4>
//             <h4 className="mt-0 modal-title text-nowrap text-uppercase">{courseId ? course.course_name : seleted.course_name}</h4>

//           </div>
//           <Card>
//             <Card.Body className="d-flex flex-column justify-content-between">
//               <div className="d-flex flex-column">
//                 <span>
//                   <span className="fw-bold ">Date</span>:<span className="mx-1">{seleted.course_date}</span>
//                 </span>
//                 <span className=""><span className="fw-bold">Location:</span><span className="mx-1">{seleted.location}</span></span>


//               </div>
//               <div className="d-flex flex-row justify-content-between">
//               <div className="d-flex flex-column">
//               <span>
//                <span className="fw-bold">Time</span>:<span className="mx-1">{seleted.course_time}</span>
//                </span>
//                 <div>
//                   <span className="fw-bold ">Total Seats:</span><span className="mx-1">{seleted.total_seats}</span>
//                 </div>
               

//               </div>
//               <div className="d-flex flex-column">
//               <span>
//                   <div ><span className="fw-bold">Left Seats:</span><span className="mx-1">{seleted.seats_left}</span></div>
//                 </span>
//                 <div ><span className="fw-bold ">Price:</span> <span className="mx-1">{"$seleted.price"}</span></div>
//               </div>
//               </div>

//             </Card.Body>
//           </Card>
//           <p style={{ lineHeight: '20px', maxHeight: '80px', overflow: 'hidden', textOverflow: 'ellipsis', textJustify: 'left' }}>

//             {courseId ? course.course_description : seleted.course_description}

//           </p>
//           <div className="d-flex gap-1">
//             <Button to={`/course/${seleted.course_id}`} target="_blank" as={Link} variant="secondary" className="w-50 px-1" onClick={closeSecondModal}>
//               View Course
//             </Button>
//             <Button className="w-50 px-1" onClick={() => handleEnrollClick(seleted, index)}>
//             Enroll
//             </Button>
//           </div>
//         </div>
//       </div>

//     </div>
//   ))
// )