import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Breadcrumb from "../../components/Breadcrumb";
import CourseListMain from "./CourseListMain";
import ScrollToTop from "../../components/ScrollTop";
import Calendar from "../../components/Google-calender/Calender";

const CourseList = () => {
  return (
    <body className="courses-list-page">
      <Header parentMenu="home" topbarEnable="enable" />

      <div className="react-wrapper">
        <div className="react-wrapper-inner">
          <Breadcrumb pageTitle="Course List" />
          <Calendar />
          <CourseListMain />
     

          {/* scrolltop-start */}
          <ScrollToTop />
          {/* scrolltop-end */}
        </div>
      </div>

      <Footer />
    </body>
  );
};

export default CourseList;

