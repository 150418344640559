import { Link } from "react-router-dom";

// Image
import aboutImg from "../../assets/images/firstAidImages/first-aid-training-2023-11-27-05-22-37-utc.jpg";
// import aboutBadge from '../../assets/images/about/iStock-1349671671.jpg';

const About = () => {
  return (
    <div className="about__area about__area_one p-relative pt---10 pb---120">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="about__image">
              <img src={aboutImg} alt="About" />
              {/* <img className="react__shape__ab" src={aboutBadge} alt="Shape Image" /> */}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about__content">
              <h2
                className="about__title wow animate__fadeInUp"
                data-wow-duration="0.3s"
              >
                Welcome to <br /> <em> Pioneer First Aid</em>
              </h2>
              <p
                className="about__paragraph wow animate__fadeInUp"
                data-wow-duration="0.5s"
              >
              We are passionate about providing high-quality < b>first aid</b> training to empower individuals and communities. Our courses cater to all levels, from beginners to professionals, ensuring that everyone is equipped with the skills needed to respond to emergencies confidently.
              </p>
              <p
                className="about__paragraph2 wow animate__fadeInUp"
                data-wow-duration="0.7s"
              >
                {" "}
                Have questions? <Link to="#"> Get Free Guide </Link>
              </p>
              <p className="wow animate__fadeInUp" data-wow-duration="0.6s">
              With expert trainers and a hands-on approach, we aim to create a safer, more prepared society. Whether you're seeking certification for work or personal knowledge, our accredited programs will give you the tools- to make a real difference in critical moments.
              </p>
              <ul className="wow animate__fadeInUp" data-wow-duration="0.9s">
                {/* <li><Link to="/about" className="more-about"> Read More <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></Link></li> */}
                <li className="last-li">
                  <em>Get Support</em>
                  <a href="mailto:info@pioneerfirstaid.com.au">
                    info@pioneerfirstaid.com.au
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
