import React, { useState, useEffect } from "react";
import { all_courses_for_homepage, getAPI } from "../../Api";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { BACKEND_URL } from "../../global";

const Feature = ({ itemClass }) => {
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    getAllCourses();
  }, []);

  const getAllCourses = async () => {
    const data = await getAPI(all_courses_for_homepage);
    setCourses(data);
  };

  console.log("courses", courses);
  const settings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 2,
  };
  return (
    <div className="react_populars_topics react_populars_topics2 react_populars_topics_about pb---80">
      <div className="react__title__section react__title__section-all">
        <div className="row">
          <div className="col-md-12 text-center">
            <h6 className="wow animate__fadeInUp" data-wow-duration="0.3s">
              Pioneer available courses
            </h6>
            <h2
              className="react__tittle wow animate__fadeInUp"
              data-wow-duration="0.5s"
            >
              Available Courses
            </h2>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row pt---30">
          <Slider {...settings}>
            {courses &&
              courses.map((data, index) => (
                <div
                  className="col-md-4 wow animate__fadeInUp"
                  data-wow-duration="0.5s"
                  key={index}
                >
                  <div className="item__inner " style={{ margin: "0 3px" }}>
                    <Link to={`/course/${data.id}`}>
                      <div
                        className={
                          itemClass ? itemClass : "course__item mb-30  "
                        }
                      >
                        <div className="course__thumb">
                          <img
                            src={`${BACKEND_URL}/${data.course_image}`}
                            alt="image"
                            style={{ height: "9rem", width: "100%" }}
                          />
                        </div>
                        <div className="course__inner">
                          <ul>
                            <li>
                              {data.course_duration
                                ? data.course_duration
                                : "4"}
                            </li>
                          </ul>
                          <h3
                            className="react-course-title "
                            style={{ height: "2rem" }}
                          >
                            {data.course_name}
                            <br />
                          </h3>
                          <div
                            className="course__card-icon d-flex "
                            style={{
                              justifyContent: "space-between",
                              marginTop: "5rem",
                            }}
                          >
                            <div className="course__card-icon--1">
                              <span>Code:{data.course_code}</span>
                            </div>
                            <div className="react__user">
                              <span>
                                ${data.course_fee ? data.course_fee : "22"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Feature;
