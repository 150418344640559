import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/logos/Pioneer-First-Aid-White-text-logo.png";
import "../../assets/scss/_menu.scss";
const Footer = (props) => {
  const { footerLogo, footerClass } = props;
  return (
    <footer
      id="react-footer"
      className={footerClass ? footerClass : "react-footer home-main"}
    >
      <div className="footer-top"> 
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="footer-widget footer-widget-1">
                <div className="footer-logo">
                  <Link to="/" className="logo-text">
                    <img src={Logo} alt="Footer Logo" />
                    
                  </Link>
                </div>
                <p className="footer-description">
                  Discover our range of courses and events tailored for your
                  needs.
                </p>
                <ul className="footer-address">
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-phone"
                    >
                      <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
                    </svg>
                    <a href="tel:+(61) 447 190 260 / +(61) 861 867 713">
                      {" "}
                      +(61) 447 190 260 / +(61) 861 867 713{" "}
                    </a>
                  </li>
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-mail"
                    >
                      <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                      <polyline points="22,6 12,13 2,6"></polyline>
                    </svg>
                    <a href="mailto:info@pioneerfirstaid.com.au">
                      info@pioneerfirstaid.com.au
                    </a>
                  </li>
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                       className="feather feather-phone"
                      style={{ width: "2rem",marginBottom:"auto" ,height:"3rem",marginLeft:"-2px" }}
                    >
                      <path d="M21 10c0 7-9 13-9 13S3 17 3 10a9 9 0 1 1 18 0z"></path>
                      <circle cx="12" cy="10" r="3"></circle>
                    </svg>

                    <p
                     
                      style={{ marginLeft: "5px",fontWeight:"bolder",color:"white" }}
                    >
                      Level 1 / 150 Adelaide Terrace East Perth | Western
                      Australia |
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="footer-widget footer-widget-2">
                <ul className="footer-menu">
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <Link to="/course">Courses</Link>
                  </li>
                  <li>
                    <Link to="/faq">FAQ</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="footer-widget footer-widget-3">
                <ul className="social-links" style={{marginTop:"-3px"}}>
                  <li className="follow">Follow us</li>
                  <li>
                    <Link to="#">
                      <span
                        aria-hidden="true"
                        className="social_facebook"
                      ></span>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <span
                        aria-hidden="true"
                        className="social_twitter"
                      ></span>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <span
                        aria-hidden="true"
                        className="social_linkedin"
                      ></span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="footer-bottom-left">
            <p>
              © <Link to="/">PioneerFirstAid</Link> Designed by
              <a
                href="https://binarymindtechnologies.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                &nbsp;
                <span style={{ textDecoration: "underline " }}>
                  BinaryMind Technologies
                </span>
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;