import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Breadcrumb from "../../components/Breadcrumb";
import ContactMain from "./ContactMain";
import ScrollToTop from "../../components/ScrollTop";


const Contact = () => {
  

  return (
    <>
      <Header parentMenu="home" topbarEnable="enable" />

      <div class="react-wrapper">
        <div class="react-wrapper-inner">
          <Breadcrumb pageTitle="Contact" />

          <ContactMain />

          {/* scrolltop-start */}
          <ScrollToTop />
          {/* scrolltop-end */}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Contact;
